import * as React from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { useDispatch /* , useSelector*/ } from "react-redux";
import { Text } from "@twilio-paste/core/text";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase /* , updatePreEngagementData*/ } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { /* AppState,*/ EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, /* fieldStyles,*/ titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";

export const PreEngagementFormPhase = () => {
    // const { name, email, tel } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const dispatch = useDispatch();
    // const [checked, setChecked] = React.useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession(/* {
                formData: {
                    nomeCompleto: name,
                    email,
                    celular,
                    aceiteComunicacoes: checked ? "S" : "N"
                }
            }*/);
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <>
            <Header customTitle="Bibi - Assistente Virtual" />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <img src="https://chatfront.plurismidia.com.br/bibi_welcome.png" alt="Bibi Welcome" style={{ width:"150px"}}/>
                <Text {...titleStyles} as="h3" style={{fontSize: '1.5rem', color: "rgb(18, 28, 45)"}}>
                    Olá!
                </Text>
                <Text {...introStyles} as="h4" style={{fontSize: '0.875rem', color: "rgb(18, 28, 45)"}}>
                    Seja Bem-vindo(a), sou a Bibi, assistente virtual da Bristol Myers Squibb
                    <span role="img" aria-label="smile">
                        😊
                    </span>
                    .
                </Text>
                {/* <Box {...fieldStyles}>
                    <Label htmlFor="name">Nome completo</Label>
                    <Input
                        type="text"
                        placeholder="Por favor, digite seu nome completo"
                        name="name"
                        data-test="pre-engagement-chat-form-name-input"
                        value={name}
                        onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                        required
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="email">E-mail</Label>
                    <Input
                        type="email"
                        placeholder="Por favor, digite seu email"
                        name="email"
                        data-test="pre-engagement-chat-form-email-input"
                        value={email}
                        onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                        required
                    />
                </Box>

                <Box {...fieldStyles}>
                    <Label htmlFor="tel">Celular</Label>
                    <Input
                        type="tel"
                        maxLength={15}
                        placeholder="Por favor, digite seu celular"
                        name="tel"
                        data-test="pre-engagement-chat-form-tel-input"
                        value={tel}
                        onChange={(e) => dispatch(updatePreEngagementData({ tel: e.target.value }))}
                        required
                    />
                </Box>

                <Box {...fieldStyles}>
                    <Checkbox
                        id="aceiteComunicacoes"
                        name="aceiteComunicacoes"
                        checked={checked}
                        onChange={(event) => {
                            setChecked(event.target.checked);
                        }}
                    >
                        Aceito comunicações futuras.
                    </Checkbox>
                    <Checkbox id="aceiteTermos" name="aceiteTermos" required>
                        Aceito os termos de consentimento e política de privacidade.&nbsp;
                        <a
                            href="https://bms.com/br/privacy-policy/service-channels-privacy-policy.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Clique aqui
                        </a>
                        &nbsp;para ler os termos.
                    </Checkbox>
                    </Box>*/}

                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                    Iniciar conversa
                </Button>
            </Box>
        </>
    );
};
