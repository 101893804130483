import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { Avatar, Button, ScreenReaderOnly } from "@twilio-paste/core";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { Key, KeyboardEvent, MouseEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import remarkGfm from 'remark-gfm'
import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
import { detachFiles } from "../store/actions/genericActions";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getAvatarContainerStyles,
    getInnerContainerStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import ReactMarkdown from "react-markdown";
import React from "react";


const doubleDigit = (number: number) => `${number < 10 ? 0 : ""}${number}`;

export const MessageBubble = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus,
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    const dispatch = useDispatch();
    const [read, setRead] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, conversation, attachedFiles, messages } = useSelector(
        (state: AppState) => ({
            conversationsClient: state.chat.conversationsClient,
            participants: state.chat.participants,
            users: state.chat.users,
            fileAttachmentConfig: state.config.fileAttachment,
            conversation: state.chat.conversation,
            attachedFiles: state.chat.attachedFiles || [],
            messages:state.chat.messages
        })
    );
    const messageRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);


    
    
    

    const renderMedia = () => {
        
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleFocus = () => {
        // Necessary since screen readers can set the focus to any focusable element
        updateFocus(message.index);
    };

    // RODRIGO SOUZA - ENVIAR MENSAGEM VIA BOTÃO
    const myJson = JSON.stringify(message.attributes);
    class ButtonEspec {
        value = "";

        description = "";
    }
    interface attributes {
        buttons: ButtonEspec[];
    }

    let buttons: attributes | undefined;

    if (myJson !== "{}" && myJson.indexOf("buttons") !== -1) {
        buttons = JSON.parse(myJson);
    }

    const buttonClick = async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        if (conversation !== undefined) {
            if (e.currentTarget.value.indexOf("https://") !== -1 || e.currentTarget.value.indexOf("http://") !== -1) {
                window.open(e.currentTarget.value);
            } else {
                let preparedMessage = conversation.prepareMessage();
                preparedMessage = preparedMessage.setBody(e.currentTarget.value);

                attachedFiles.forEach((file: File) => {
                    const formData = new FormData();
                    formData.append(file.name, file);
                    preparedMessage.addMedia(formData);
                });
                await preparedMessage?.build().send();

                dispatch(detachFiles(attachedFiles));
            }
        }
    };

    

    function listarButtons(button: ButtonEspec) {
        return (
            <Box key={button.value} display="inline-block" width="100%" marginY="spaceNegative10">
                <br />
                <Button
                    size="small"
                    fullWidth={true}
                    value={button.value}
                    variant={
                        button.value.indexOf("http") === -1 && button.value.indexOf("https") === -1
                            ? "secondary"
                            : "link"
                    }
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        buttonClick(e);
                    }}
                >
                   <ReactMarkdown components={{p:React.Fragment}}>{button.description ? button.description : button.value}</ReactMarkdown> 
                </Button>
            </Box>
        );
    }

    
    const author = users?.find((u) => u.identity === message.author)?.friendlyName || message.author;
    const bottomRef = useRef(null);

    // Rodrigo SOUZA - AVATAR do BOT (Linha 180)
    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                {!belongsToCurrentUser && (
                    <Box
                        {...getAvatarContainerStyles(!isLastOfUserGroup)}
                        data-testid="avatar-container"
                        backgroundColor="transparent"
                    >
                        {
                            /* isLastOfUserGroup &&*/
                            <Avatar
                                size="sizeIcon60"
                                name="icone Bristol"
                                src="https://chatfront.plurismidia.com.br/icon-bristol.png"
                            />
                        }
                    </Box>
                )}
                <Box display='block' {...getInnerContainerStyles(belongsToCurrentUser)} 
                style={belongsToCurrentUser ? {backgroundColor:"rgb(190, 43, 187)", color:"rgb(255, 255, 255)"} : {backgroundColor:"rgb(244, 244, 246)", color:"rgb(18, 28, 45)"}}>
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                        <Text {...authorStyles} as="p" aria-hidden style={{ textOverflow: "ellipsis", color: "inherit", fontSize: "0.85rem", fontWeight: "700", padding: "0px" }} title={author}>
                            {author}
                        </Text>
                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser
                                ? "You sent at"
                                : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                        </ScreenReaderOnly>
                        <Text {...timeStampStyles} as="p" style={{ color: "inherit", fontSize: "0.85rem", padding: "0px" }}>
                            {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                                message.dateCreated.getMinutes()
                            )}`}
                        </Text>
                    </Flex>
                    <Text as="p" {...bodyStyles} ref={bottomRef} style={{color:"inherit",fontSize: "0.85rem", padding: "0px" }}>
                        {message.body && messages[0].author === message.author ? message.body : 
                        <ReactMarkdown components={{ p: React.Fragment,}}remarkPlugins={[remarkGfm]}>{message.body}</ReactMarkdown>}
                        {/* {message.body ? parseMessageBody(message.body, belongsToCurrentUser) : null} */}
                              
                    </Text>
                    {buttons ? buttons.buttons.map(listarButtons, this) : null}
                    {message.type === "media" ? renderMedia() : null}
                    { bottomRef.current?.scrollIntoView({behavior: 'smooth'}) /* rolagem IPHONE */ } 
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles} style={{ color: "inherit", fontSize: "0.75rem", padding: "0px" }}>
                        Lido
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
