import { useEffect, Fragment, useState } from "react";
import { Message } from "@twilio/conversations";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { AppState } from "../store/definitions";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import { removeNotification, updatePreEngagementData } from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
   
    const { conversationState, messages } = useSelector((state: AppState) => ({
        conversationState: state.chat.conversationState,
        messages: state.chat.messages
    }));

    useEffect(() => {
        dispatch(updatePreEngagementData({ email: "", name: "", tel: "" }));
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;
    let stateEspec = conversationState;

    // LUCAS ROCHA - Foi removido o (buttonsbool) e suas validações para arrumar o erro de "too many renders" do react, 
    //impossibilitando de trabalhar com outros atributos além do "buttons"
    // RODRIGO SOUZA - VERIFICA SE O MESSAGES ATTRIBUTES DO FLOW TEM BOTÕES (buttonsBool)
    let messageOut: Message | undefined;
    messages?.map((message: Message, i: number) => {
        if (messages.length - 1 === i) {
            messageOut = message;
        }
        return message;
    });
    const myJson = JSON.stringify(messageOut?.attributes);
    
    if (myJson !== "{}" && myJson.indexOf("conversationState") !== -1) {
        stateEspec = "closed";
    } 

    // if (myJson !== "{}" && myJson.indexOf("conversationState") !== -1) {
    //     stateEspec = "closed";
    // } else {
    //     if ((myJson === "{}" || myJson === undefined || myJson.indexOf("buttons") === -1) && buttonsBool === true) {
    //         setButtonsBool(false);
    //     }

    //     if (myJson !== "{}" && myJson.indexOf("buttons") && buttonsBool === false) {
    //         setButtonsBool(true);
    //     }
    // }


    return (
        <Wrapper>
            <Header customTitle="Bibi - Assistente Virtual" />
            <NotificationBar />
            <MessageList/>
            {stateEspec === "active" ? <MessageInput message={messageOut}/> : <ConversationEnded />}
        </Wrapper>
    );
};
