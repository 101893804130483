import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";

import { containerStyles, titleStyles } from "./styles/Header.styles";
import { useSelector } from "react-redux";
import { AppState } from "../store/definitions";


export const Header = ({ customTitle }: { customTitle?: string }) => {
    //const expanded = useSelector((state: AppState) => state.session.expanded);
    const chat = document.getElementById("buttonChat")
    const imageEyecatcher = document.getElementsByClassName('eyecatcher-img')[0] as HTMLImageElement
    const textEyecatcher = document.getElementsByClassName('eyecatcher-text')[0]
//Botão Close LUCAS ROCHA E SILVA
    const Close = () =>{
        if(chat){
            chat.style.display = 'none'
            imageEyecatcher.src = 'https://chatfront.plurismidia.com.br/bibi_welcome.png'
            imageEyecatcher.style.height = '3rem' 
            textEyecatcher.innerHTML = 'Fale com a Bibi'
        }
        console.log(chat.style.display)

       // dispatch(changeExpandedStatus({ expanded: !expanded }))
        
    }

    return (
        <Box as="header" {...containerStyles}>
            <Text as="h2" {...titleStyles} style={{fontSize: '0.875rem', color: "rgb(255, 255, 255)"}}>
                {customTitle || "Custom title"}
            </Text>
            <Box as="button" 
            style={{background:'transparent', border:'none', cursor:'pointer', color:"white"}} 
            onClick={()=>Close()}>
                <CloseIcon decorative={true} color="currentColor" title="Description of icon" />
            </Box>
        </Box>
    );
};
